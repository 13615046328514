import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import { dev } from '$app/environment'
import { PUBLIC_SENTRY_DSN } from '$env/static/public'

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  environment: dev ? 'development' : 'production',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: dev ? 1.0 : 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [replayIntegration()]
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
